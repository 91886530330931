var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemInfo wr"},[_c('div',{staticClass:"info-a wr f-cb"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pinfo-container wr swiper-container-horizontal swiper-container-android"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-active"},[_c('img',{attrs:{"src":require('@/img/waterRenvueSystem.png'),"alt":""}})])])])]),_vm._m(0)]),_c('div',{staticClass:"view"},[_c('div',{staticClass:"info-b wr"},[_c('div',{staticClass:"tit-48 mc lh1 hnb"},[_vm._v("产品特点")]),_c('div',{staticClass:"list f-cb"},[_c('div',{staticClass:"li"},[_c('div',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"自动化抄表"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("自动化抄表")])]),_vm._m(1)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"智能账单处理"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("智能账单处理")]),_vm._m(2)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"数据分析与报告"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("数据分析与报告")]),_vm._m(3)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"客户管理与服务"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("客户管理与服务")]),_vm._m(4)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"欠费和异常监测"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("欠费和异常监测")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"tit-60 mc hnb"},[_vm._v("水务营收及抄表管理系统")]),_c('div',{staticClass:"tit-18 t1 hnb"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 集成了先进的计量设备、数据收集技术和客户管理功能，实现了从水表读取到账单生成和支付的全流程自动化。系统支持远程抄表和实时监控用户 用水情况，能够快速更新数据、自动生成账单并处理支付，显著提高了营收管理的效率和准确性。 ")])]),_c('p',{staticStyle:{"line-height":"1.75em"}},[_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 利用无线或有线远程抄表技术，自动收集用水数据，相比传统人工抄表，显著减少了时间和成本。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 自动计算水费并生成电子账单，提供多种电子支付渠道，支持在线查询和支付，提升客户服务体验。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 分析用户用水模式和支付行为并生成报告，帮助管理层监控营收和预测收入。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 集成客户管理工具，提供用户档案维护、服务请求处理和客户互动，提升客户满意度和忠诚度。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 实时监控用水异常和欠费情况，自动触发提醒或采取必要措施，保障营收安全 ")])])])
}]

export { render, staticRenderFns }